import React, {Component, Suspense} from 'react';
//import {useSubscription} from '@apollo/react-hooks';
//import gql from 'graphql-tag';

//let mounts = require('../../gamedata/json/en/mounts.json');
//let minions = require('../../gamedata/json/en/minions.json');

let HomeRoute = require('./home').default;
let CharProfileRoute = require('./char/profile').default;
let CharRaidsRoute = require('./char/raids').default;
let CharMetasRoute = require('./char/metas').default;
let CharFishRoute = require('./char/fish').default;
let CharTimelineRoute = require('./char/timeline').default;
let CharLevesRoute = require('./char/leves').default;
let CharOrchestrionsRoute = require('./char/orchestrions').default;
let CharBardingsRoute = require('./char/bardings').default;
let CharEmotesRoute = require('./char/emotes').default;
let CharFashionsRoute = require('./char/fashions').default;
let CharHairsRoute = require('./char/hairs').default;
let CharTitlesRoute = require('./char/titles').default;
let CharAchievementsRoute = require('./char/achievements').default;
let CharMountsRoute = require('./char/mountsInner').default;
let CharVerifyRoute = require('./char/verify').default;
let CharSettingsRoute = require('./char/settings').default;
let CharMoogleTomesRoute = require('./char/moogletomes').default;
let CharShopsRoute = require('./char/shops').default;
let CharTTCardsRoute = require('./char/ttcards').default;
let CharTTNpcsRoute = require('./char/ttnpcs').default;
let CharBozjaNotesRoute = require('./char/bozjaNotes').default;
let CharPortraitsRoute = require('./char/portraits').default;
//let CharMountRoute = require('./char/mount').default;
//let CharMinionRoute = require('./char/minion').default;
let dataCenters = require('../gamedata/json/dataCenters.json');

let recentChars = require('../stores/recentChars').default;
let userStore = require('../stores/user').default;
let charStore = require('../stores/char').default;
let auth0 = require('../auth0').default;

let componentFetch = require('../helpers/componentFetch');

/*export default function CharRoute({url, lang}) {

	let QUERY = gql`
subscription characters($characterId:Int!) {
  characters_by_pk(id: $characterId) {
    id
    achievementsPrivate
    deleted
    createdAt
    updatedAt
    iconUrl
    imageUrl
    legacy
    name
    world {
      id
      name
      dataCenter {
        id
        name
      }
    }
    rankingsAchievements {
      count
      rank
      globalRank
    }
    rankingsMounts {
      count
      rank
      globalRank
    }
    rankingsMinions {
      count
      rank
      globalRank
    }
    recentAchievements:achievements(order_by:{createdAt:desc},limit:3) {
      achievement {
        id
        name:name_en
        icon
      }
    }
    recentMounts:mounts(order_by:{createdAt:desc},limit:3) {
      mount {
        id
        name:name_en
      }
    }
    recentMinions:minions(order_by:{createdAt:desc},limit:3) {
      minion {
        id
        name:name_en
      }
    }
  }
}`;
	const {data, error, loading} = useSubscription(QUERY, {
		variables: {characterId:url[1]}
	});
	if (loading) {
		return <div className="LoadingOverlay LoadingOverlay-Visible">Loading...</div>
	}
	if (error) {
		return <div>Error.</div>
	}

	return <CharRouteInner url={url} lang={lang} data={data} char={data.characters_by_pk} />;
}*/


class CharRouteInner extends Component {
	/*state = {
		bgloading: false
	};
	constructor(props) {
		super(props);
		this.sync = this.sync.bind(this);
	}*/
	state = charStore.getData();
	constructor(props) {
		super(props);
		this.fetch = componentFetch.bind(this);
		this.favorite = this.favorite.bind(this);
		this.sync = this.sync.bind(this);
		charStore.on(this.onChange, this);
		userStore.on(this.onChange, this);
	}
	componentWillUnmount() {
		charStore.off(this.onChange, this);
		userStore.off(this.onChange, this);
	}
	onChange() {
		if (this.state.noChar) return;
		this.setState(charStore.getData());
	}

	componentDidMount() {
		if (this.props.url[0] !== 'char') {
			this.setState({loading: false, status: 'ready', noChar: true, char:{id:-1}});
		} else {
			charStore.loadChar(this.props.url[1]);
			//this.fetch('char', '/api/charcache/' + this.props.url[1] + '/');
		}
	}
	componentDidUpdate(prevProps, prevState) {
		console.log(prevProps.url, this.props.url);
		if (prevProps.url[0] === this.props.url[0] && prevProps.url[1] === this.props.url[1]) return;
		if (this.props.url[0] !== 'char') {
			this.setState({loading: false, status: 'ready', noChar: true, char: {id:-1}});
		} else {
			this.setState({noChar: false, loading: true});
			recentChars.logCharVisit(this.state.char);
			charStore.loadChar(this.props.url[1]);
		}
	}
	favorite(e) {
		e.preventDefault();
		userStore.toggleFavoriteChar(this.state.char.id);
		/*let favoriteChars = userStore.getFavoriteChars(), isFavorite = false;
		if (favoriteChars.find(c => c.characterId === this.state.char.id)) {
			isFavorite = true;
		}
		if (isFavorite) {
			auth0.fetch('/api/user/favoritechars/' + this.state.char.id, {method: 'DELETE'});
		} else {
			auth0.fetch('/api/user/favoritechars/' + this.state.char.id, {method: 'POST'});
		}*/
	}
	sync() {
		/*fetch(`/api/charrealtime/${this.state.char.id}/`).then(() => {
			this.setState({bgloading: false});
		});*/
		//this.fetch('char', '/api/charrealtime/' + this.props.url[1] + '/');
		charStore.sync();
		//this.setState({bgloading: true});
	}
	/*componentDidUpdate(prevProps, prevState) {
		recentChars.logCharVisit(this.state.char);
		//charStore.loadChar(this.props.url[1]);
		//if (this.props.char.)
		if ((new Date()).getTime() > this.state.char.updatedAt + (1000*60*60*6)) {
			this.sync();
		}
	}*/
	render() {
		let lang = this.props.lang;
		let urlBase = '/';
		if (lang !== 'en') urlBase += lang + '/';
		//urlBase += lang + '/';
		//console.log(this.props.url);
		let charId = this.props.url[1], currentPage = this.state.noChar ? this.props.url[0] : this.props.url[2];
		let detailsUrl = this.state.noChar ? this.props.url.slice(1, this.props.url.length) : this.props.url.slice(3, this.props.url.length);
		if (currentPage === '' && this.state.noChar) currentPage = 'home';
		else if (currentPage === '' && !this.state.noChar) currentPage = 'profile';
		let char = this.state.char;
		let sideMenu, bgText, content, updating;
		if (this.state.noChar) {
			char = {
				id: -1
			};
		} else {
			urlBase += 'char/' + char.id + '/';
		}
		let isCharMine = userStore.getUserChars().find(c => c.id === char.id);
		let charData, mobileCharData, profileLink, userLinks, settingsLink;
		if (this.state.loading) {
			content = <div>Loading...</div>;
		} else if (char.deleted) {
			content = <p>This character has been deleted.</p>;
		} else if (char.status === 'adding') {
			content = <div className="PageContent">
				<div className="InfoBox">
					<img src="/images/eureka.png" width="190" height="125" />
					<div>
						<h2>This character is new to Lalachievements</h2>
						<p>Please wait a minute for it to be added.</p>
						<p>If you continue to get this message, either Lodestone or the Lalachievements character server are down for maintenance.</p>
					</div>
				</div>
			</div>;
		} else if (this.state.status === 'serverError') {
			content = <div className="PageContent"><div className="InfoBox InfoBox-Error">
				<img src="/images/error.png" width="190" height="125" />
				<div><p>Error fetching character.</p></div>
			</div></div>;
		} else if (char.status === 'notFound') {
			content = <div className="PageContent"><div className="InfoBox InfoBox-Error">
				<img src="/images/error.png" width="190" height="125" />
				<div><p>Character could not be found.</p></div>
			</div></div>;
		} else if (this.state.status === 'unknownError') {
			content = <div className="PageContent"><div className="InfoBox InfoBox-Error">
				<img src="/images/error.png" width="190" height="125" />
				<div><p>Error fetching character.</p></div>
			</div></div>;
		} else {//if (!this.state.loading && (char.status === 'ready' || char.status === 'updating')) {
			if (this.state.bgloading) {
				updating = <div className="BgLoading InfoBox">
						<img src="/images/eureka.png" width="190" height="125" />
						<div>
							<p className="BgLoading-Icon"><i className="fontello-spin4 animate-spin" /></p>
							<p>Character is being updated from lodestone, this should take around 10-15 seconds.</p>
							<p>This message will disappear when finished.</p>
						</div>
					</div>;
			}
			/*if (this.state.char.wouldUpdateButMaint) {
				updating = <p className="BgLoading">
				 <p>Cannot update character data as Lodestone is under maintenance.</p>
				 </p>;
			}*/
			if (!this.state.noChar) {
				let dataCenter = 'unknown';
				let world = 'unknown';
				dataCenters.forEach(dc => {
					dc.Worlds.forEach(w => {
						if (w.Id === char.worldId) {
							world = w.Name;
							dataCenter = dc.Name;
						}
					});
				});
				let deselectUrl = '/';
				if (lang !== 'en') deselectUrl += lang + '/';
				if (currentPage !== 'home' && currentPage !== 'profile') deselectUrl += currentPage + '/';
				deselectUrl += detailsUrl.join('/');
				charData = <div className="SideMenu-Char">
					<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
					<div className="SideMenu-CharName">{char.name}</div>
					<div className="SideMenu-CharWorld">{dataCenter} / {world}</div>
					<div className="SideMenu-Deselect"><a href={deselectUrl}>X</a></div>
				</div>;
				mobileCharData = <div className="SideMenu-Char SideMenu-Char-Mobile">
					<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
					<div className="SideMenu-CharName">{char.name}</div>
					<div className="SideMenu-CharWorld">{dataCenter} / {world}</div>
					<div className="SideMenu-Deselect"><a href={deselectUrl}>X</a></div>
				</div>;
				profileLink = <a href={`${urlBase}`} className={currentPage==='profile'?'current':''}><i className="fontello-home" /> Profile</a>;
				if (userStore.getUser()) {
					let favoriteChars = userStore.getFavoriteChars(), isFavorite = false;
					if (favoriteChars.find(c => c.characterId === char.id)) {
						isFavorite = true;
					}
					if (isCharMine) {
						//settingsLink = <a href={`${urlBase}settings/`} className={currentPage==='settings'?'current':''}><i className="fontello-cog" /> Settings</a>;
					} else {
						settingsLink = <a href={`${urlBase}verify/`} className={currentPage==='verify'?'current':''}><i className="fontello-unverified" /> Verify Ownership</a>;
					}
					userLinks = <div className="SideMenu-UserLinks">
						<a href="#" onClick={this.favorite}><i
							className={isFavorite ? "fontello-heart fontellocolor-red" : "fontello-heart-empty"}/> Favorite
						</a>
						{settingsLink}
					</div>;
				} else {
					userLinks = <div className="SideMenu-UserLinks">Log in to favorite or verify ownership of this character.</div>;
				}
			} else {
				profileLink = <a href={`${urlBase}`} className={currentPage==='profile'?'current':''}><i className="fontello-home" /> Home</a>;
			}
			sideMenu = <div className="SideMenu">
				{charData}
				{ /*<div className="InfoBox InfoBox-Error InfoBox-Small">
					<div>
						Dawntrail data will be added when early access ends on July 2.<br />
						Achievements, mounts, minions, and rankings will not update during early access.
					</div>
				</div>*/ }
				{char.private ? '' : <div className="SideMenu-Links">
					{profileLink}
					{!this.state.noChar ? <a href={`${urlBase}timeline/`} className={currentPage==='timeline'?'current':''}><i className="fontello-timeline" /> Timeline</a> : ''}
					<a href={`${urlBase}titles/`} className={currentPage==='titles'?'current':''}>
						<i className="fontello-titles" /> Titles
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.titleCount/char.titlePossible*100+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}achievements/Battle/`} className={currentPage==='achievements'?'current':''}>
						<i className="fontello-achievement" /> Achievements
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.achievementPoints/char.achievementPointsPossible*100+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}mounts/`} className={currentPage==='mounts'?'current':''}>
						<i className="fontello-mounts" /> Mounts
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.mountPercent+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}minions/`} className={currentPage==='minions'?'current':''}>
						<i className="fontello-minions" /> Minions
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.minionPercent+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}fashions/`} className={currentPage==='fashions'||currentPage==='hairstyles'?'current':''}>
						<i className="fontello-umbrella" /> Fashion &amp; Hairstyles
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.fashionCombinedPercent+'%'}}>&nbsp;</span> : ''}
					</a>
					{ /*<a href={`${urlBase}portraits/`} className={currentPage==='portraits'?'current':''}>
						<i className="fontello-portraits" /> Portraits
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.portraitPercent+'%'}}>&nbsp;</span> : ''}
					</a>*/ }
					<a href={`${urlBase}leves/`} className={currentPage==='leves'?'current':''}>
						<i className="fontello-leves" /> Levequests
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.levePercent+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}ttcards/`} className={currentPage==='ttcards'||currentPage==='ttnpcs'?'current':''}>
						<i className="fontello-triad" /> Triple Triad
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.triadCardPercent+'%'}}>&nbsp;</span> : ''}
					</a>
                    <a href={`${urlBase}fish/`} className={currentPage==='fish'?'current':''}>
						<i className="fontello-fish" /> Fish
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.fishPercent+'%'}}>&nbsp;</span> : ''}
                    </a>
                    <a href={`${urlBase}orchestrions/`} className={currentPage==='orchestrions'?'current':''}>
						<i className="fontello-music" /> Orchestrion Rolls
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.orchestrionPercent+'%'}}>&nbsp;</span> : ''}
                    </a>
					<a href={`${urlBase}bardings/`} className={currentPage==='bardings'?'current':''}>
						<i className="fontello-bardings" /> Bardings
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.bardingPercent+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}emotes/`} className={currentPage==='emotes'?'current':''}>
						<i className="fontello-wink" /> Emotes
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.emotePercent+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}bozjanotes/`} className={currentPage==='bozjanotes'?'current':''}>
						<i className="fontello-notes" /> Bozjan Field Notes
						{!this.state.noChar ? <span className="SideMenuLinkInner" style={{width:char.bozjaNotePercent+'%'}}>&nbsp;</span> : ''}
					</a>
					<a href={`${urlBase}raids/`} className={currentPage==='raids'?'current':''}><i className="fontello-raids" /> Raids</a>
					{ /*<a href={`${urlBase}portraits/`} className={currentPage==='portraits'?'current':''}>Portraits (WIP)</a>*/ }
					<a href={`${urlBase}shops/`} className={currentPage==='shops'?'current':''}>Shops (WIP)</a>
					<a href={`${urlBase}moogletomes/`} className={currentPage==='moogletomes'?'current':''}>Moogle Treasure Trove{/*Fall Guys*/}</a>
				</div>}
				{userLinks}
				{ /*<div className="SideMenu-Patreon">
					<a href="https://discord.gg/jUfj3NV">
						<img src="/images/Discord-Logo-White.png" width="24" height="24" alt="Discord Logo" />
						<p> Discord</p>
					</a>
					<a href="https://www.patreon.com/lalachievements">
						<img src="/images/Patreon_Mark_White.png" width="24" height="24" alt="Patreon Logo" />
						<p> Subscribe on Patreon!</p>
					</a>
				</div>*/ }
			</div>;
			/*bgText = <div><h1 class="Char-BackgroundName">{char.name}</h1>
			 <span class="Char-Race">{char.gender} {char.clan} {char.race}</span></div>;*/
			if (char.private) {
				content = <div>
					<p>This character's data is private.</p>
					<p>This is usually because the character's privacy settings are set to either private or logged-in users <a href="https://na.finalfantasyxiv.com/lodestone/my/setting/account/" target="_blank">on Lodestone</a>. "Character Profile Page" and "Character Information: Profile" must both be set to public for a character to be visible on this site.</p>
					<p>If you have recently changed this setting, you can request a sync with Lodestone to update your character.</p>
					<p>{this.state.bgloading ? 'Sync queued.' : <button onClick={this.sync}><i className="fontello-sync" /> Sync Now</button>}</p>
				</div>;
			} else if (currentPage === 'verify') {
				content = <CharVerifyRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'settings') {
				content = <CharSettingsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} sync={this.sync} reload={this.reload} />
			} else if (currentPage === 'achievements') {
				content = <CharAchievementsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'metas') {
				content = <CharMetasRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'raids') {
				content = <CharRaidsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'fish') {
				content = <CharFishRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
				//content = <div>No.</div>;
            } else if (currentPage === 'timeline') {
                content = <CharTimelineRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'leves') {
				content = <CharLevesRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'orchestrions') {
				content = <CharOrchestrionsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'bardings') {
				content = <CharBardingsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'emotes') {
				content = <CharEmotesRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'fashions') {
				content = <CharFashionsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'hairstyles') {
				content = <CharHairsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'titles') {
				content = <CharTitlesRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'mounts') {
				content = <CharMountsRoute lang={lang} char={char} type="mount" url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'minions') {
				content = <CharMountsRoute lang={lang} char={char} type="minion" url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'moogletomes') {
				content = <CharMoogleTomesRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'ttcards') {
				content = <CharTTCardsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'ttnpcs') {
				content = <CharTTNpcsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'bozjanotes') {
				content = <CharBozjaNotesRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			 } else if (currentPage === 'portraits') {
				content = <CharPortraitsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'shops') {
				content = <CharShopsRoute lang={lang} char={char} url={detailsUrl} urlBase={urlBase} />
			} else if (currentPage === 'profile') {
				content = <CharProfileRoute lang={lang} char={char} url={detailsUrl} data={this.props.data} sync={this.sync} syncing={this.state.bgloading} urlBase={urlBase} />;
			} else if (currentPage === 'home') {
				content = <HomeRoute lang={lang} urlBase={urlBase} />;
			} else {
				content = '404';
			}
		} /*else {
			content = <div>Error fetching character</div>;
		}*/

		/*if (!this.state.loading && (this.state.status === 'ready' || this.state.status === 'updating') && char.deleted) {
			content = <p>This character has been deleted.</p>;
		} else if (!this.state.loading && (this.state.status === 'ready' || this.state.status === 'updating')) {

			if (this.state.status === 'updating') {
				updating = <p className="BgLoading">
					<p className="BgLoading-Icon"><i className="fontello-spin4 animate-spin" /></p>
					<p>Character is being updated from lodestone, this should take around 10-15 seconds.</p>
					<p>This message will disappear when finished.</p>
				</p>;
			}
			if (charId !== '-') {
				let dataCenter = 'unknown';
				let world = 'unknown';
				dataCenters.forEach(dc => {
					dc.Worlds.forEach(w => {
						if (w.Id === char.worldId) {
							world = w.Name;
							dataCenter = dc.Name;
						}
					});
				});
				charData = <div className="SideMenu-Char">
					<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
					<div className="SideMenu-CharName">{char.name}</div>
					<div className="SideMenu-CharWorld">{dataCenter} / {world}</div>
				</div>;
				mobileCharData = <div className="SideMenu-Char SideMenu-Char-Mobile">
					<img className="SideMenu-Icon" src={char.iconUrl} alt="" />
					<div className="SideMenu-CharName">{char.name}</div>
					<div className="SideMenu-CharWorld">{dataCenter} / {world}</div>
				</div>;
				profileLink = <a href={`${urlBase}`} className={currentPage==='profile'?'current':''}><i className="fontello-profile" />Profile</a>;
			}
			sideMenu = <div className="SideMenu">
				{charData}
				{profileLink}
				<a href={`${urlBase}timeline/`} className={currentPage==='timeline'?'current':''}><i className="fontello-timeline" /> Timeline</a>
				<a href={`${urlBase}achievements/Battle/Battle/`} className={currentPage==='achievements'?'current':''}><i className="fontello-achievement" /> Achievements</a>
				<a href={`${urlBase}mounts/`} className={currentPage==='mounts'?'current':''}><i className="fontello-mounts" /> Mounts</a>
				<a href={`${urlBase}minions/`} className={currentPage==='minions'?'current':''}><i className="fontello-minions" /> Minions</a>
				<a href={`${urlBase}raids/`} className={currentPage==='raids'?'current':''}><i className="fontello-raids" /> Raids</a>
			</div>;
			if (currentPage === 'achievements') {
				content = <CharAchievementsRoute lang={lang} char={char} url={this.props.url} />
			} else if (currentPage === 'raids') {
				content = <CharRaidsRoute lang={lang} char={char} />
			} else if (currentPage === 'fish') {
				content = <CharFishRoute lang={lang} char={char} />
			} else if (currentPage === 'timeline') {
				content = <CharTimelineRoute lang={lang} char={char} url={this.props.url} />
			} else if (currentPage === 'mounts') {
				content = <CharMountsRoute lang={lang} char={char} type="mount"/>
			} else if (currentPage === 'minions') {
				content = <CharMountsRoute lang={lang} char={char} type="minion" />
			} else if (currentPage === 'profile') {
				content = <CharProfileRoute char={char}/>;
			} else {
				content = '404';
			}
		} else if (this.state.loading) {
			content = <div>Loading...</div>;
		} else if (this.state.status === 'adding') {
			content = <div className="PageContent">
				<h2>This character is new to Lalachievements</h2>
				<p>Please wait a minute for it to be added.</p>
				<p>If you continue to get this message, either Lodestone or the Lalachievements character server are down for maintenance.</p>
			</div>;
		} else if (this.state.status === 'serverError') {
			content = <div>Error fetching character</div>;
		} else if (this.state.status === 'notFound') {
			content = <div>Character could not be found</div>;
		} else if (this.state.status === 'unknownError') {
			content = <div>Error fetching character</div>;
		}*/
		return (
			<div>

				<div className="PageContent PageContent-WithMenu">
					{sideMenu}
					{bgText}
					{mobileCharData}
					<div className="PageNav PageNav-MobileMenu">
						<a href={'/'+this.props.url.join('/')} className="PageNav-MenuToggle" onClick={this.props.toggleBurger}>☰ <span className="PageNav-MenuToggleText">{currentPage} (tap to open menu)</span></a>
					</div>
					{updating}
					{content}
				</div>
			</div>
		);
	}
}

export default CharRouteInner;