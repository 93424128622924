let urlify = require('urlify').create();

let bluLog = [2451, 2452, 2453, 2728, 3319];
let bluRaids = [2454, 2455, 2456, 2457, 2458, 2459, 2460, 2461, 2462, 2463, 2464, 2465, 2466, 2467, 2468, 2469, 2470, 2471, 2472, 2473, 2474, 2475, 2476, 2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 3320, 3321, 3322, 3323, 3325, 3326, 3327, 3328, 3329, 3330];
let bluCarnivale = [2203, 2204, 2205, 2206, 2207, 2208, 2449, 2209, 2210, 2211, 2450, 2727, 3318];
let ddSolos = [1659, 1660, 1952, 2054, 3184, 3182, 3183];
let hoard = [1661, 1662, 1663, 1664, 2056, 3184, 1665, 1666];
let ultimates = [1993, 2107, 2444, 3074, 3162];
let fishOceanLog = [2553, 2603, 2554, 2604, 2556, 2606, 2555, 2605, 2748, 2751, 2749, 2752, 2750, 2753, 3256, 3260, 3257, 3261, 3258, 3262, 3259, 3263];
let fishOcean = [2557, 2558, 2559, 2757, 2560, 2561, 2562, 2759, 3264, 3265, 3266, 2563, 2564, 2565, 2566, 2754, 2755, 2756, 3267, 3268, 3269, 2888, 2889, 2758];
let ishgardCRP = [2489, 2490, 2491, 2527, 2648, 2807];
let ishgardBSM = [2492, 2493, 2494, 2528, 2649, 2808];
let ishgardARM = [2495, 2496, 2497, 2529, 2650, 2809];
let ishgardGSM = [2498, 2499, 2500, 2530, 2651, 2810];
let ishgardLTW = [2501, 2502, 2503, 2531, 2652, 2811];
let ishgardWVR = [2504, 2505, 2506, 2532, 2653, 2812];
let ishgardALC = [2507, 2508, 2509, 2533, 2654, 2813];
let ishgardCUL = [2510, 2511, 2512, 2534, 2655, 2814];
let ishgardMIN = [2513, 2514, 2515, 2541, 2542, 2543, 2535, 2656, 2815];
let ishgardBTN = [2516, 2517, 2518, 2544, 2545, 2546, 2536, 2657, 2816];
let ishgardFSH = [2547, 2548, 2549, 2537, 2658, 2817];
let ishgard = [2522, 2524, 2523, 2525, 2526, 2550, 2551, 2552, 2538, 2539, 2540, 2890];
let resplendent = [2821, 2822, 2823, 2824, 2826, 2825, 2827, 2828, 2829];
let island = [3089, 3186, 3254, 3399, 3090, 3091, 3092, 3093, 3094, 3095, 3096, 3097, 3098, 3099, 3100, 3101, 3102, 3103, 3255, 3104];

let metaAchievements = [
	{
		id: 1,
		name: "Achievements I",
		achievements: [
			{
				id: 1000,
				name: "Jack of All Trades",
				howTo: "Obtain every achievement listed in Battle and Dungeons, not including blue mage, solo deep dungeons, or accursed hoard.",
				achievements: [],
				achievementCategories: [1, 2],
				excludedAchievements: [...bluLog, ...bluRaids, ...bluCarnivale, ...ddSolos, ...hoard],
				items: [],
			},
			{
				id: 1001,
				name: "Monday Night C41",
				howTo: "Obtain every achievement listed in Trials and Raids, not including ultimate raids.",
				achievements: [],
				achievementCategories: [3, 4],
				excludedAchievements: [...ultimates],
				items: [],
			},
			{
				id: 1002,
				name: "Bluest BLU",
				howTo: "Obtain every Blue Mage achievement listed in Battle and Dungeons.",
				achievements: [...bluLog, ...bluRaids, ...bluCarnivale],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1003,
				name: "Aetheryte Camper",
				howTo: "Obtain every achievement listed in The Hunt.",
				achievements: [],
				achievementCategories: [5],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1004,
				name: "Finally Free",
				howTo: "Obtain every achievement listed in Treasure Hunt.",
				achievements: [],
				achievementCategories: [6],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1005,
				name: "Sword of the Field",
				howTo: "Obtain every achievement listed in Field Operations.",
				achievements: [],
				achievementCategories: [71],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1006,
				name: "Crystal Pusher",
				howTo: "Obtain every achievement listed in The Wolves' Den.",
				achievements: [],
				achievementCategories: [9],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1007,
				name: "I Miss Slaughter",
				howTo: "Obtain every achievement listed in Frontline.",
				achievements: [],
				achievementCategories: [10],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1008,
				name: "Nevermore",
				howTo: "Obtain every achievement listed in Rival Wings.",
				achievements: [],
				achievementCategories: [11],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1009,
				name: "Lost in the Saucer",
				howTo: "Obtain every achievement listed in Gold Saucer.",
				achievements: [],
				achievementCategories: [18],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1010,
				name: "Fish Fear Me",
				howTo: "Obtain every achievement listed in Fisher, not including ocean fishing or ishgard reconstruction.",
				achievements: [],
				achievementCategories: [34],
				excludedAchievements: [...ishgardFSH, ...fishOcean, ...fishOceanLog],
				items: [],
			},
			{
				id: 1011,
				name: "I'm On a Boat!",
				howTo: "Obtain every ocean fishing achievement listed in Fisher.",
				achievements: [...fishOcean, ...fishOceanLog],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1012,
				name: "Saint of the Diadem",
				howTo: "Obtain every ishgard reconstruction achievement.",
				achievements: [...ishgardCRP, ...ishgardBSM, ...ishgardARM, ...ishgardGSM, ...ishgardLTW, ...ishgardWVR, ...ishgardALC, ...ishgardCUL, ...ishgardMIN, ...ishgardBTN, ...ishgardFSH, ...ishgard],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1013,
				name: "Macromancer",
				howTo: "Obtain every disciple of the hand achievement, not including ishgard reconstruction.",
				achievements: [],
				achievementCategories: [24, 25, 26, 27, 28, 29, 30, 31],
				excludedAchievements: [...ishgardCRP, ...ishgardBSM, ...ishgardARM, ...ishgardGSM, ...ishgardLTW, ...ishgardWVR, ...ishgardALC, ...ishgardCUL, ...resplendent],
				items: [],
			},
			{
				id: 1014,
				name: "East Shroud's Greatest Wind Shard Collector",
				howTo: "Obtain every disciple of the land achievement, not including ishgard reconstruction.",
				achievements: [],
				achievementCategories: [32, 33],
				excludedAchievements: [...ishgardMIN, ...ishgardBTN],
				items: [],
			},
			{
				id: 1015,
				name: "Flower Smelling Champion",
				howTo: "Obtain every island sanctuary achievement.",
				achievements: [...island],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1016,
				name: "I Did That",
				howTo: "Obtain every achievement listed in Collectables, Desynthesis, and Materia, not including custom deliveries.",
				achievements: [2939, 2940, 1297],
				achievementCategories: [21, 23],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 1017,
				name: "Loremaster",
				howTo: "Obtain every achievement listed in Quests, Main Scenario, and Job/Role Quests.",
				achievements: [],
				achievementCategories: [35, 76, 77],
				excludedAchievements: [],
				items: [],
			},
		]
	},
	{
		id: 2,
		name: "Achievements II",
		achievements: [
			{
				id: 2000,
				name: "Gotta Go Fast",
				howTo: "Discover every location within Sastasha.",
				achievements: [663],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2001,
				name: "Stuck in Furiten",
				howTo: "Achieve a mahjong rating of more than 2,000.",
				achievements: [2180, 2181, 2182],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2002,
				name: "Do WoLs Dream of Accursed Hoards?",
				howTo: "Discover 20,000 pieces of the Accursed Hoard, and 100 pieces of the Accursed Hoard without using a pomander or protomander of intuition.",
				achievements: [...hoard],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2003,
				name: "I Hope Mentor Will Notice Me",
				howTo: "Complete 2,000 duties via Duty Roulette: Mentor.",
				achievements: [1472, 1473, 1474, 1475, 1603, 1604],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2004,
				name: "TCG Addict",
				howTo: "Collect 376 unique Triple Triad cards, and all cards numbered 1 through 312.",
				achievements: [1100, 1101, 1102, 1372, 1488, 1631, 1908, 2078, 2368, 2643, 3020, 3209, 2819],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2005,
				name: "Legend BTW",
				howTo: "Clear every ultimate raid.",
				achievements: [...ultimates],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2006,
				name: "The Lone Once and Future Necromancer",
				howTo: "Clear every deep dungeon solo.",
				achievements: [...ddSolos],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2007,
				name: "It Begins",
				howTo: "Level every job to 90, not including limited jobs.",
				achievements: [2923, 2924, 2926, 2927],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2008,
				name: "Speed",
				howTo: "Clear a savage raid tier within three weeks of its release.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2009,
				name: "Endurance",
				howTo: "Clear an ultimate raid within four months of its release.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2010,
				name: "You've Got a Friend In Me",
				howTo: "Deliver 150 collectables to each custom delivery client.",
				achievements: [1784, 1979, 2077, 2193, 2435, 2633, 2845, 3069, 3173, 3361],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 2011,
				name: "For the People",
				howTo: "Reach the maximum rank with all tribal reputations.",
				achievements: [],
				achievementCategories: [37],
				excludedAchievements: [],
				items: [],
			},
		]
	},
	{
		id: 3,
		name: "Relics",
		achievements: [
			{
				id: 3000,
				name: "Book Wyrm",
				howTo: "Obtain every zeta weapon.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [10054, 10055, 10056, 10057, 10058, 10059, 10060, 10061, 10062, 10064],
			},
			{
				id: 3001,
				name: "Unknowable Thought",
				howTo: "Obtain every anima weapon.",
				achievements: [],
				achievementCategories: [64],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3002,
				name: "Cassie Prepper",
				howTo: "Obtain every eureka weapon.",
				achievements: [],
				achievementCategories: [66],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3003,
				name: "Queen Slayer",
				howTo: "Obtain every resistance weapon.",
				achievements: [],
				achievementCategories: [68],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3004,
				name: "Manderville Man",
				howTo: "Obtain every manderville weapon.",
				achievements: [],
				achievementCategories: [75],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3005,
				name: "Master Crafter",
				howTo: "Obtain every disciple of the hand Skysteel, Resplendent, and Splendorous tool, and one Lucis tool.",
				achievements: [1078, 2787, 2788, 2789, 2790, 2791, 2792, 2793, 2794, 2821, 2822, 2823, 2824, 2826, 2825, 2827, 2828, 3362, 3363, 3364, 3365, 3366, 3367, 3368, 3369],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3006,
				name: "Master Gatherer",
				howTo: "Obtain every disciple of the land Skysteel, Resplendent, and Splendorous tool, and one Lucis tool.",
				achievements: [1079, 2795, 2796, 2797, 2830, 2831, 2832, 3370, 3371, 3372],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3007,
				name: "Strong Grip",
				howTo: "Obtain every Padjali weapon from Palace of the Dead.",
				achievements: [1581, 1582, 1583, 1584, 1585, 1588, 1587, 1586, 1589, 1590, 1591, 1592, 1593, 1962, 1963, 2394, 2395, 3001, 3002],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3008,
				name: "Stronger Grip",
				howTo: "Obtain every Kinna weapon from Palace of the Dead.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [
					16152, 16153, 16154, 16155, 16156, 16157, 16158, 16159, 16160, 16161, 16162, 16163, 16164,
					20458, 20459, 27349, 27350, 35775, 35757, 43634, 43655
				],
			},
			{
				id: 3009,
				name: "May the Carnage Never End",
				howTo: "Obtain every Empyrean weapon from Heaven on High.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3010,
				name: "The Sword in the Stone",
				howTo: "Obtain every Enaretos weapon from Eureka Orthos.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3011,
				name: "Bored...",
				howTo: "Obtain every Eureka Anemos armor set.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
			{
				id: 3012,
				name: "Limit Breaker",
				howTo: "Obtain every weapon from any one ultimate raid.",
				achievements: [],
				achievementCategories: [],
				excludedAchievements: [],
				items: [],
			},
		]
	},
	{
		id: 4,
		name: "Mounts and Minions",
		achievements: [
			{
				id: 4000,
				name: "Eorzea's Pet Collector",
				howTo: "Obtain every minion from A Realm Reborn.",
			},
			{
				id: 4001,
				name: "Eorzea's Tamer",
				howTo: "Obtain every mount from A Realm Reborn.",
			},
			{
				id: 4002,
				name: "Dravania's Pet Collector",
				howTo: "Obtain every minion from Heavensward.",
			},
			{
				id: 4003,
				name: "Dravania's Tamer",
				howTo: "Obtain every mount from Heavensward.",
			},
			{
				id: 4004,
				name: "Othard's Pet Collector",
				howTo: "Obtain every minion from Stormblood.",
			},
			{
				id: 4005,
				name: "Othard's Tamer",
				howTo: "Obtain every mount from Stormblood.",
			},
			{
				id: 4006,
				name: "Norvrandt's Pet Collector",
				howTo: "Obtain every minion from Shadowbringers.",
			},
			{
				id: 4007,
				name: "Norvrandt's Tamer",
				howTo: "Obtain every mount from Shadowbringers.",
			},
			{
				id: 4008,
				name: "The Stars' Pet Collector",
				howTo: "Obtain every minion from Endwalker.",
			},
			{
				id: 4009,
				name: "The Stars' Tamer",
				howTo: "Obtain every mount from Endwalker.",
			},
			{
				id: 4010,
				name: "Tural's Tamer",
				howTo: "Obtain every mount from Dawntrail.",
			},
			{
				id: 4011,
				name: "Tural's Tamer",
				howTo: "Obtain every mount from Dawntrail.",
			},
			{
				id: 4012,
				name: "Menagerie Keeper",
				howTo: "Obtain every minion and mount.",
			},
		]
	},
];

module.exports = metaAchievements.map(category => {
	category.slug = urlify(category.name);
	category.achievements = category.achievements.map(meta => {
		meta.slug = urlify(meta.name);
		return meta;
	});
	return category;
});